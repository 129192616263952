:root {
    --toastify-color-info: #0e2c66;
    --toastify-color-success: #046a45;
    --toastify-color-warning: #e2b208;
    --toastify-color-error: #d91b1b;
}

html,
body,
*:not(.ql-container *) {
    font-family: Poppins, sans-serif;
}

.primary_button {
    background: #1790cf;
    color: #fff;
}

textarea {
    text-align: inherit;
}

.auditor-compliance-tabs svg {
    color: #000;
    width: 15px !important;
    height: 15px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

button {
    outline: none !important;
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
    color: #000 !important;
}

.view-modal-list-element {
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-right: 2rem;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    color: #333;
}

.view-modal-list-element:first-child {
    border-top: 1px solid #ccc;
}

.view-modal-list-element span {
    /* width: 50%;
    max-width: 250px; */
}

legend {
    width: 0 !important;
}

.auth-aside-design {
    background-image: linear-gradient(to bottom, #3b82f680, #3b82f680, #f1c40f29, #f1c40f29);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
}

.ant-btn-primary {
    @apply bg-sky-500;
}
.ant-upload-list-item-error {
    border-color: #d9d9d9 !important;
}

.ant-modal.title-start .ant-modal-content .ant-modal-header {
    justify-content: flex-start;
}
@media print {
    html,
    body {
        page-break-after: avoid !important;
        page-break-before: avoid !important;
        height: 100%; /* Use 100% here to support printing more than a single page*/
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }
    /* .print-display-none,
    .print-display-none * {
      display: none !important;
    }
    .print-visibility-hide,
    .print-visibility-hide * {
      visibility: hidden !important;
    } */
    #ciso-dashboard {
        visibility: visible !important;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.custom-scroll::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.custom {
    .ant-collapse-content-box {
        padding: 0;
    }
}

.section_1[size='A4'][layout='portrait'] {
    width: 29.7cm;
    height: 21cm;
}
@media print {
    body,
    page {
        margin: 0;
        box-shadow: 0;
    }
}

.certificate-container {
    font-family: 'Poppins, sans-serif' !important;
    letter-spacing: 0.02em; /* Adjust for better spacing */
    line-height: 1.2; /* Ensure proper line rendering */
    -webkit-font-smoothing: antialiased; /* Better font clarity */
    background: white;
    &,
    * {
        font-family: 'Poppins', sans-serif !important;
    }
}

.ql-container.ql-snow {
    border: none !important;
    font-size: 15px;
    font-family: sans-serif, serif, monospace, 'Times New Roman', Times;
}

.read-only .ql-container.ql-snow .ql-editor {
    background-color: #ffffff;
    color: #64748b;
    width: 100%;
    min-height: 11in;
    padding: 0.2in 1in;
    margin: 0 auto;
    border-radius: 0;
    box-shadow: none;
}

.ql-container.ql-snow .ql-editor {
    background-color: #ffffff;
    color: #64748b;
    width: 8.5in;
    min-height: 11in;
    padding: 0.75in 1in;
    margin: 0.25in auto;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #e2e8f0, 0 2px 4px 0 rgba(0, 0, 0, 0.06), 0 1px 1.5px 0 rgba(0, 0, 0, 0.03);
    overflow: visible;
}

.ql-snow .ql-editor a {
    cursor: pointer;
}

.quill-better-table-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer;
}

.quill-better-table-wrapper::-webkit-scrollbar-track {
    background-color: #f7fafc;
}

.quill-better-table-wrapper::-webkit-scrollbar-thumb {
    background-color: #e2e8f0;
    border-radius: 1px;
}

.ql-container .qlbt-selection-line.qlbt-selection-line-top,
.ql-container .qlbt-selection-line.qlbt-selection-line-bottom,
.ql-container .qlbt-selection-line.qlbt-selection-line-right,
.ql-container .qlbt-selection-line.qlbt-selection-line-left,
.ql-container .qlbt-selection-line.qlbt-selection-line-left::before,
.ql-container .qlbt-selection-line.qlbt-selection-line-right::before {
    background-color: #002c72;
}

table.quill-better-table td {
    padding: 4px 8px;
}

.ql-editor select.ql-ui {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    color: #4a5568;
    font-size: 0.875rem;
    padding: 0 0.25rem;
    top: 0.4rem;
    opacity: 0;
    transition: opacity 0.2s;
    cursor: pointer;
}

.ql-editor select.ql-ui:hover {
    opacity: 1;
}

.ql-snow .ql-editor .ql-code-block-container {
    border-radius: 0.25rem;
    padding: 0.5rem;
}

.ql-snow .ql-editor code {
    background-color: #23241f98;
    color: #f8f8f2;
    padding: 0.25rem;
}

.ql-page-break {
    display: block;
    position: relative;
    width: 100%;
    height: 2rem;
    margin: 0.75in 0;
}

.ql-page-break::before {
    position: absolute;
    z-index: 2;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 131.5%;
    height: 100%;
    background-color: #f7fafc;
    border-radius: 2px;
    border-top: #00000009 1px solid;
    border-bottom: #00000009 1px solid;
}

.ql-comment-active {
    background-color: #ffff00;
}

.ql-editor ol li:is([data-list='bullet'])::before {
    content: '•' !important;
}

table.quill-better-table td {
    border: 1px solid #ddd;
}

table.quill-better-table tr:nth-child(odd) {
    background-color: #eee;
}

table.quill-better-table tr:first-child {
    font-weight: bold;
}

.qlbt-operation-menu {
    z-index: 10000;
}
